import PropTypes from 'prop-types'
import React from 'react'
import pic01 from '../images/pic01.jpg'


class Main extends React.Component {
  render() {
    let close = (
      <div
        className="close"
        onClick={() => {
          this.props.onCloseArticle()
        }}
        onKeyPress={() => {
          this.props.onCloseArticle()
        }}
        role="button"
        tabIndex="0">
        Save
      ></div>
    )

    return (
      <div
        ref={this.props.setWrapperRef}
        id="main"
        style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}
      >
        <article
          id="About"
          className={`${this.props.article === 'intro' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">About</h2>
          <span className="image main">
            <img src={pic01} alt="" />
          </span>
          <p>
          We have been serving the St. Petersburg and Tampa Bay Area for over 30 years.
          Mr. Driscoll has been the City Attorney for the Cities of New Port Richey, St. Pete Beach and Gulfport, for a combined 26 years.
          He currently serves as a special hearing officer for the City of St. Petersburg.
          Over the years, we have served various cities throughout the area in a wide variety of legal matters.
          </p>
          <p>
          Our clients include condominium and homeowner associations throughout the area, as well as small businesses.
          We can address your business and association needs for everything from contract drafting to litigation over virtually any dispute.
          Our personal service practice includes all family law matters, as well as the preparation of wills, trusts and other estate documents.
          We provide mediation services and we handle all civil appeals. 
          </p>
          <p>
          The Driscoll Law Firm has developed a reputation in the community for providing the highest quality legal services, while always striving to limit our clients’ expenditures of legal fees.
          Call us today for a free consultation.
          </p>
          {close}
        </article>

        <article
          id="work"
          className={`${this.props.article === 'work' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Practice</h2>
          <span className="image main">
          
          </span>
          <p>
            At the Driscoll Law Firm, we focus on the following practice areas:
            <br />
            <br />Family Law
            <br />Business Law
            <br />Litigation
            <br />Mediation
            <br />Appeals
            <br />Government Law
            <br />Wills and Trusts
          </p>
          {close}
        </article>

        <article
          id="about"
          className={`${this.props.article === 'about' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Services</h2>
          <span className="image main">
          
          </span>
          <p>
          At the Driscoll Law Firm, we offer the following services:
            <br />
            <br />Wills
            <br />Trusts
            <br />Power of Attorney
            <br />Living Wills
            <br />Contracts
            <br />Marital Agreements
            <br />Prenuptial Agreements
            <br />Divorce
            <br />Child Support and Timesharing
            <br />Alimony
            <br />Paternity
            <br />Appeals
            <br />Business Litigation
            <br />Sales of businesses
            <br />Construction Law
            <br />Mediations
            <br />Foreclosures
          </p>
          {close}
        </article>

        <article
          id="contact"
          className={`${this.props.article === 'contact' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Contact</h2>
    <form method="post" netlify-honeypot="bot-field" data-netlify="true">
    <input type="hidden" name="bot-field" />
            <div className="field half first">
              <label htmlFor="name">Name</label>
              <input type="text" name="name" id="name" />
            </div>
            <div className="field half">
              <label htmlFor="email">Email</label>
              <input type="text" name="email" id="email" />
            </div>
            <div className="field">
              <label htmlFor="message">Message</label>
              <textarea name="message" id="message" rows="4"></textarea>
            </div>
            <ul className="actions">
              <li>
                <input type="submit" value="Send Message" className="special" />
              </li>
              <li>
                <input type="reset" value="Reset" />
              </li>
            </ul>
          </form>
        <p>
        <br/>146 Second Street North, Suite 310
        <br/>Saint Petersburg, Florida 33701
        <br/>Phone: (727) 896-1144
        </p>

          {close}
        </article>
      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main
